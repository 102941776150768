import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useFormik } from 'formik'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import Page from '../../../layout/Page/Page'
import Card, { CardBody } from '../../../components/bootstrap/Card'
import FormGroup from '../../../components/bootstrap/forms/FormGroup'
import Input from '../../../components/bootstrap/forms/Input'
import Button from '../../../components/bootstrap/Button'
import Logo from '../../../components/Logo'
import useDarkMode from '../../../hooks/useDarkMode'
import AuthContext from '../../../contexts/authContext'
import Spinner from '../../../components/bootstrap/Spinner'
import authService from '../../../services/auth'

interface ILoginHeaderProps {
	isNewUser?: boolean
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className="text-center h1 fw-bold mt-5">Create Account,</div>
				<div className="text-center h4 text-muted mb-5">Sign up to get started!</div>
			</>
		)
	}
	return (
		<>
			<div className="text-center h1 fw-bold mt-5">Καλωσήρθες,</div>
			<div className="text-center h4 text-muted mb-5">κάνε είσοδο για να συνεχίσεις!</div>
		</>
	)
}
LoginHeader.defaultProps = {
	isNewUser: false,
}

interface ILoginProps {
	isSignUp?: boolean
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { setUser } = useContext(AuthContext)

	const { darkModeStatus } = useDarkMode()

	const [signInPassword, setSignInPassword] = useState<boolean>(false)
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp)

	const navigate = useNavigate()
	const handleOnClick = useCallback(() => (window.location.href = '/'), [navigate])

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {}

			if (!values.loginUsername) {
				errors.loginUsername = 'Απαραίτητο'
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Απαραίτητο'
			}

			return errors
		},
		validateOnChange: false,
		onSubmit: (values) => {
			const credentials = {
				identifier: values.loginUsername,
				password: values.loginPassword,
			}

			authService.login(credentials).then((result: any) => {
				console.log('Result is : ', result)
				if (setUser && result.status === 200) {
					setUser(result.data.jwt)
					handleOnClick()
					localStorage.setItem('facit_asideStatus', 'true')
					const userData = encodeURIComponent(JSON.stringify(result.data.user))
					localStorage.setItem('transporters_user', userData)
				} else {
					formik.setFieldError('loginPassword', 'Ο συνδυασμός email/όνομα χρήστη και κωδικού πρόσβασης δεν βρέθηκε.')
				}
			})
			console.log(`Formik values are ${values}`)
		},
	})

	const [isLoading, setIsLoading] = useState<boolean>(false)

	const handleContinue = () => {
		setIsLoading(true)
		setTimeout(() => {
			setSignInPassword(true)
			setIsLoading(false)
		}, 1000)
	}

	// useEffect(() => {
	// 	localStorage.removeItem('jwtToken')
	// }, [])

	return (
		<PageWrapper isProtected={false} title={singUpStatus ? 'Sign Up' : 'Login'} className={classNames({ 'bg-dark': !singUpStatus, 'bg-light': singUpStatus })}>
			<Page className="p-0">
				<div className="row h-100 align-items-center justify-content-center">
					<div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
						<Card className="shadow-3d-dark" data-tour="login-page">
							<CardBody>
								<div className="text-center my-5">
									<Link
										to="/"
										className={classNames('text-decoration-none  fw-bold display-2', {
											'text-dark': !darkModeStatus,
											'text-light': darkModeStatus,
										})}
										aria-label="Facit"
									>
										<Logo width={200} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}
								></div>

								<LoginHeader isNewUser={singUpStatus} />

								<form className="row g-4">
									{singUpStatus ? (
										<>
											{/* <div className="col-12">
												<FormGroup id="signup-email" isFloating label="Your email">
													<Input type="email" autoComplete="email" />
												</FormGroup>
											</div>
											<div className="col-12">
												<FormGroup id="signup-name" isFloating label="Your name">
													<Input autoComplete="given-name" />
												</FormGroup>
											</div>
											<div className="col-12">
												<FormGroup id="signup-surname" isFloating label="Your surname">
													<Input autoComplete="family-name" />
												</FormGroup>
											</div>
											<div className="col-12">
												<FormGroup id="signup-password" isFloating label="Password">
													<Input type="password" autoComplete="password" />
												</FormGroup>
											</div>
											<div className="col-12">
												<Button color="info" className="w-100 py-3" onClick={handleOnClick}>
													Sign Up
												</Button>
											</div> */}
										</>
									) : (
										<>
											<div className="col-12">
												<FormGroup
													id="loginUsername"
													isFloating
													label="Το email σου ή όνομα χρήστη"
													className={classNames({
														'd-none': signInPassword,
													})}
												>
													<Input
														autoComplete="username"
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={formik.errors.loginUsername}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({})
														}}
													/>
												</FormGroup>
												{signInPassword && <div className="text-center h4 mb-3 fw-bold">Γεια σου, {formik.values.loginUsername}.</div>}
												<FormGroup
													id="loginPassword"
													isFloating
													label="Κωδικός Πρόσβασης"
													className={classNames({
														'd-none': !signInPassword,
													})}
												>
													<Input
														type="password"
														autoComplete="current-password"
														value={formik.values.loginPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={formik.errors.loginPassword}
														validFeedback="Looks good!"
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>
											</div>
											<div className="col-12">
												{!signInPassword ? (
													<Button color="warning" className="w-100 py-3" isDisable={!formik.values.loginUsername} onClick={handleContinue}>
														{isLoading && <Spinner isSmall inButton isGrow />}
														Συνέχεια
													</Button>
												) : (
													<Button color="warning" className="w-100 py-3" onClick={formik.handleSubmit}>
														Είσοδος
													</Button>
												)}
											</div>
										</>
									)}
								</form>
							</CardBody>
						</Card>
						<div className="text-center">
							<a
								href="/"
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}
							>
								Privacy policy
							</a>
							<a
								href="/"
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}
							>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	)
}
Login.propTypes = {
	isSignUp: PropTypes.bool,
}
Login.defaultProps = {
	isSignUp: false,
}

export default Login
