import axios from 'axios'
import { API_BASE_URL } from '../config/constants'

class ApiService {
	constructor() {
		const apiClient = axios.create({
			baseURL: `${API_BASE_URL}`, // Replace with your API base URL
			timeout: 10000, // Timeout after 10 seconds
			headers: {
				'Content-Type': 'application/json',
				// Add other custom headers if needed
			},
		})
		// Request interceptor
		apiClient.interceptors.request.use(
			(config) => {
				// Add any custom logic before request is sent, like adding authentication token
				const token = localStorage.getItem('jwtToken')
				if (token) {
					config.headers.Authorization = `Bearer ${token}`
				}
				return config
			},
			(error) => {
				return Promise.reject(error)
			}
		)
		// Response interceptor
		apiClient.interceptors.response.use(
			(response) => {
				return response
			},
			(error) => {
				// Handle errors globally, show error messages, etc.
				if (error.response && error.response.status === 401) {
					// Handle unauthorized error
					// Redirect to login page or show a message
				}
				return Promise.reject(error)
			}
		)
		this.apiClient = apiClient
	}

	async get(url, params = {}) {
		try {
			const response = await this.apiClient.get(url, { params })
			return response
		} catch (error) {
			console.log(error)
			return error
		}
	}

	// async get(url, params = {}) {
	// 	return this.authClient.get(url, { params })
	// }
	async post(url, data) {
		try {
			const response = await this.apiClient.post(url, data)
			console.log(response)
			return response
		} catch (error) {
			console.log(error)
			return error
		}
	}
	async put(url, data) {
		try {
			const response = await this.apiClient.put(url, data)
			console.log(response)
			return response
		} catch (error) {
			console.log(error)
			return error
		}
	}
	async delete(url) {
		try {
			const response = await this.apiClient.delete(url)
			console.log(response)
			return response
		} catch (error) {
			console.log(error)
			return error
		}
	}
}
const apiService = new ApiService()
export default apiService
