import axios from 'axios'
import { API_BASE_URL } from '../config/constants'

class AuthService {
	constructor() {
		const authClient = axios.create({
			baseURL: `${API_BASE_URL}`, // Replace with your API base URL
			timeout: 10000, // Timeout after 10 seconds
			headers: {
				'Content-Type': 'application/json',
				// Add other custom headers if needed
			},
		})
		// Request interceptor
		authClient.interceptors.request.use(
			(config) => {
				// Add any custom logic before request is sent, like adding authentication token
				const token = localStorage.getItem('jwtToken')
				if (token) {
					config.headers.Authorization = `Bearer ${token}`
				}
				return config
			},
			(error) => {
				return Promise.reject(error)
			}
		)
		// Response interceptor
		authClient.interceptors.response.use(
			(response) => {
				return response
			},
			(error) => {
				// Handle errors globally, show error messages, etc.
				if (error.response && error.response.status === 401) {
					// Handle unauthorized error
					// Redirect to login page or show a message
				}
				return Promise.reject(error)
			}
		)
		this.authClient = authClient
	}

	async login(credentials) {
		try {
			console.log(credentials)
			const response = await this.authClient.post('transporters/store-login', credentials)
			const { user, jwt } = response.data
			// localStorage.setItem('jwtToken', jwt)
			// console.log(response)
			// console.log(user)
			// console.log(jwt)
			return response
		} catch (error) {
			console.log(error)
			return error
		}
	}

	async getRole() {
		const response = await this.authClient.get('users/me?populate=*', this.authClient.getToken)
		return response.data.role.type
	}

	async getUser() {
		const response = await this.authClient.get('users/me?populate=*', this.authClient.getToken)
		return response.data
	}
	logout() {
		localStorage.removeItem('jwtToken')
		window.location.href = '/auth-pages/login'
	}
	getToken() {
		return localStorage.getItem('jwtToken')
	}

	get(url, params) {
		return this.authClient.get(url, { params })
	}
	post(url, data) {
		return this.authClient.post(url, data)
	}
	put(url, data) {
		return this.authClient.put(url, data)
	}
	delete(url) {
		return this.authClient.delete(url)
	}
}
const authService = new AuthService()
export default authService
