import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react'
import { io, Socket } from 'socket.io-client'
import { NewReleases } from '../components/icon/material-icons'
import { BASE_URL } from '../config/constants'

interface SocketContextType {
	socket: Socket | null
}

const SocketContext = createContext<SocketContextType | undefined>(undefined)

export const useSocket = (): Socket | null => {
	const context = useContext(SocketContext)
	if (!context) {
		throw new Error('useSocket must be used within a SocketProvider')
	}
	return context.socket
}

interface SocketProviderProps {
	children: ReactNode
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
	const [socket, setSocket] = useState<Socket | null>(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const jwt = localStorage.getItem('jwtToken')
		let newSocket: any = null
		if (jwt && jwt.toString().length > 2) {
			const SERVER_URL = `${BASE_URL}`
			newSocket = io(SERVER_URL, {
				auth: {
					strategy: 'jwt',
					token: `${jwt}`,
				},
			})
			setSocket(newSocket)
		}
		setLoading(false)

		return () => {
			if (newSocket) newSocket.close()
		}
	}, [])

	if (loading) {
		return <>Γίνεται φόρτωση...</> //later add a loading animation here??
	}

	return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>
}
