import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData'
import { useNavigate } from 'react-router-dom'
import { demoPagesMenu } from '../menu'
import { Navigate } from 'react-router-dom'

export interface IAuthContextProps {
	user: string
	setUser?(...args: unknown[]): unknown
	userData: Partial<IUserProps>
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps)

interface IAuthContextProviderProps {
	children: ReactNode
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(localStorage.getItem('jwtToken') || '')
	const [userData, setUserData] = useState<Partial<IUserProps>>({})
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	// const navigate = useNavigate()

	// const navigate = useNavigate()
	useEffect(() => {
		if ((user === '' || user.length <= 0) && !window.location.pathname.includes(`/${demoPagesMenu.login.path}`)) {
			window.location.href = `../${demoPagesMenu.login.path}`
			//auth0-jwt validator
		} else {
			localStorage.setItem('jwtToken', user)
			setIsLoggedIn(true)
		}
	}, [user])

	const value = useMemo(
		() => ({
			user,
			setUser,
			userData,
		}),
		[user, userData]
	)
	return isLoggedIn ? <AuthContext.Provider value={value}>{children}</AuthContext.Provider> : <div></div>
}
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

export default AuthContext
