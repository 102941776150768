import React, { useState, useContext, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useWindowSize } from 'react-use'
import { demoPagesMenu } from '../../menu'
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown'
import Button from '../../components/bootstrap/Button'
import useDarkMode from '../../hooks/useDarkMode'
import Collapse from '../../components/bootstrap/Collapse'
import { NavigationLine } from '../Navigation/Navigation'
import Icon from '../../components/icon/Icon'
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle'
import AuthContext from '../../contexts/authContext'
import ThemeContext from '../../contexts/themeContext'

const User = () => {
	const { width } = useWindowSize()
	const { setAsideStatus } = useContext(ThemeContext)
	const { userData, setUser } = useContext(AuthContext)
	const user = JSON.parse(decodeURIComponent('' + localStorage.getItem('transporters_user')))

	const navigate = useNavigate()
	const handleItem = useNavigationItemHandle()
	const { darkModeStatus, setDarkModeStatus } = useDarkMode()

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false)

	const { t } = useTranslation(['translation', 'menu'])

	return (
		<>
			<div className={classNames('user', { open: collapseStatus })} role="presentation" onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className="user-avatar">
					<img
						srcSet={`https://transporters.webtitans.gr/uploads/3d_illustration_person_with_sunglasses_23_2149436188_79f92e950b.avif`}
						src={`https://transporters.webtitans.gr/uploads/3d_illustration_person_with_sunglasses_23_2149436188_79f92e950b.avif`}
						alt="Avatar"
						width={128}
						height={128}
					/>
				</div>
				<div className="user-info">
					<div className="user-name d-flex align-items-center">
						{`${user.username}`}
						<Icon icon="Verified" className="ms-1" color="info" />
					</div>
					<div className="user-sub-title">{userData?.position}</div>
				</div>
			</div>
			{/* <DropdownMenu>
				<DropdownItem>
					<Button icon="AccountBox" onClick={() => navigate(`../`)}>
						Profile
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button icon={darkModeStatus ? 'DarkMode' : 'LightMode'} onClick={() => setDarkModeStatus(!darkModeStatus)} aria-label="Toggle fullscreen">
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
			</DropdownMenu> */}

			<Collapse isOpen={collapseStatus} className="user-menu">
				{/* <nav aria-label="aside-bottom-user-menu">
					<div className="navigation">
						<div
							role="presentation"
							className="navigation-item cursor-pointer"
							onClick={() =>
								navigate(
									`../`,
									// @ts-ignore
									handleItem()
								)
							}
						>
							<span className="navigation-link navigation-link-pill">
								<span className="navigation-link-info">
									<Icon icon="AccountBox" className="navigation-icon" />
									<span className="navigation-text">{t('menu:Profile') as ReactNode}</span>
								</span>
							</span>
						</div>
						<div
							role="presentation"
							className="navigation-item cursor-pointer"
							onClick={() => {
								setDarkModeStatus(!darkModeStatus)
								handleItem()
							}}
						>
							<span className="navigation-link navigation-link-pill">
								<span className="navigation-link-info">
									<Icon icon={darkModeStatus ? 'DarkMode' : 'LightMode'} color={darkModeStatus ? 'info' : 'warning'} className="navigation-icon" />
									<span className="navigation-text">{darkModeStatus ? (t('menu:DarkMode') as ReactNode) : (t('menu:LightMode') as ReactNode)}</span>
								</span>
							</span>
						</div>
					</div>
				</nav> */}
				<NavigationLine />
				<nav aria-label="aside-bottom-user-menu-2">
					<div className="navigation">
						<div
							role="presentation"
							className="navigation-item cursor-pointer"
							onClick={() => {
								localStorage.removeItem('jwtToken')
								localStorage.removeItem('transporters_user')
								navigate(`../${demoPagesMenu.login.path}`)
							}}
						>
							<span className="navigation-link navigation-link-pill">
								<span className="navigation-link-info">
									<Icon icon="Logout" className="navigation-icon" />
									<span className="navigation-text">Αποσύνδεση</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	)
}

export default User
