import React, { FC, ReactElement, ReactNode, useContext, useEffect, useLayoutEffect, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useMeasure, useWindowSize } from 'react-use'
import ThemeContext from '../../contexts/themeContext'
import Button from '../../components/bootstrap/Button'
import Portal from '../Portal/Portal'
import useDarkMode from '../../hooks/useDarkMode'
import apiService from '../../services/api'

interface IHeaderLeftProps {
	children: ReactNode
	className?: string
}
export const HeaderLeft: FC<IHeaderLeftProps> = ({ children, className }) => {
	return <div className={classNames('header-left', 'col-md', className)}>{children}</div>
}
HeaderLeft.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
}
HeaderLeft.defaultProps = {
	className: undefined,
}

interface IHeaderRightProps {
	children: ReactNode
	className?: string
}
export const HeaderRight: FC<IHeaderRightProps> = ({ children, className }) => {
	const [ref, { height }] = useMeasure<HTMLDivElement>()

	const root = document.documentElement
	root.style.setProperty('--header-right-height', `${height}px`)

	return (
		<div ref={ref} className={classNames('header-right', 'col-md-auto', className)}>
			{children}
		</div>
	)
}
HeaderRight.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
}
HeaderRight.defaultProps = {
	className: undefined,
}

interface IHeaderProps {
	children: ReactElement<IHeaderLeftProps> | ReactElement<IHeaderRightProps> | ReactNode
	hasLeftMobileMenu?: boolean
	hasRightMobileMenu?: boolean
}
const Header: FC<IHeaderProps> = ({ children, hasLeftMobileMenu, hasRightMobileMenu }) => {
	const { themeStatus } = useDarkMode()

	const userData = JSON.parse(decodeURIComponent('' + localStorage.getItem('transporters_user')))
	const windowsWidth = useWindowSize().width
	const [refMobileHeader, sizeMobileHeader] = useMeasure<HTMLDivElement>()
	const [refHeader, sizeHeader] = useMeasure<HTMLDivElement>()
	const [shiftManager, setShiftManager] = useState('')

	const root = document.documentElement
	root.style.setProperty('--mobile-header-height', `${sizeMobileHeader.height}px`)
	root.style.setProperty('--header-height', `${sizeHeader.height}px`)

	const { asideStatus, setAsideStatus, leftMenuStatus, setLeftMenuStatus, rightMenuStatus, setRightMenuStatus } = useContext(ThemeContext)

	useLayoutEffect(() => {
		if ((asideStatus || leftMenuStatus || rightMenuStatus) && windowsWidth < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)) document.body.classList.add('overflow-hidden')
		return () => {
			document.body.classList.remove('overflow-hidden')
		}
	})

	useEffect(() => {
		apiService.get(`transporters/option-get/shift_admin_id`).then((response: any) => {
			console.log(response.data)
			const userId = response.data.value
			apiService.get(`users/${userId}`).then((res: any) => {
				setShiftManager(`${res.data.firstname} ${res.data.lastname} - Τηλ : ${res.data.phone}`)
			})
		})
	}, [])

	const sendSOSRequest = () => {
		console.log(userData.role.type)
		apiService.get('transporters/send-sos')
	}

	return (
		<>
			<header ref={refMobileHeader} className="mobile-header" style={{ backgroundColor: 'black' }}>
				<div className="container-fluid">
					<div className="row" style={{ paddingTop: '2rem' }}>
						<div className="col">
							<p style={{ color: '#fff' }} className="fs-3 mt-3">
								{userData.username}
							</p>
							{/* <Button
								aria-label="Toggle Aside"
								className="mobile-header-toggle"
								size="lg"
								color={asideStatus ? 'primary' : themeStatus}
								isLight={asideStatus}
								icon={asideStatus ? 'FirstPage' : 'LastPage'}
								onClick={() => {
									console.log('im herererer')
									// setAsideStatus(!asideStatus)
									setLeftMenuStatus(false)
									setRightMenuStatus(false)
								}}
							/> */}
							{hasLeftMobileMenu && (
								<Button
									aria-label="Toggle Left Menu"
									className="mobile-header-toggle"
									size="lg"
									color={leftMenuStatus ? 'primary' : themeStatus}
									isLight={leftMenuStatus}
									icon={leftMenuStatus ? 'MoreVert' : 'MoreHoriz'}
									onClick={() => {
										// setAsideStatus(!asideStatus)
										setLeftMenuStatus(!leftMenuStatus)
										setRightMenuStatus(false)
									}}
									style={{ visibility: 'hidden' }}
								/>
							)}
						</div>

						{hasRightMobileMenu && (
							<div className="col-auto pt-3">
								<Button
									aria-label="Toggle Right Menu"
									className="mobile-header-toggle"
									size="lg"
									color={rightMenuStatus ? 'primary' : themeStatus}
									isLight={rightMenuStatus}
									icon={asideStatus ? 'Menu' : 'MenuOpen'}
									onClick={() => {
										setAsideStatus(!asideStatus)
										// setLeftMenuStatus(false)
										//setRightMenuStatus(!rightMenuStatus)
									}}
								/>
							</div>
						)}
					</div>
				</div>
			</header>
			<header
				ref={refHeader}
				className={classNames('header', {
					'header-left-open': leftMenuStatus,
					'header-right-open': rightMenuStatus,
				})}
			>
				<div className="container-fluid">
					<div className="row d-flex align-items-center">
						<div className="d-flex justify-content-end">
							{userData.role.type !== 'store' ? (
								<img
									src="https://media.istockphoto.com/id/1469080543/vector/sos-distress-signal-icon-vector-for-graphic-design-logo-website-social-media-mobile-app-ui.jpg?s=612x612&w=0&k=20&c=7TK7skVXu8aiYsqt-KegA4ujLl93F4gAyvJhXDKSjHM="
									alt=""
									width={'50px'}
									style={{ cursor: 'pointer' }}
									onClick={sendSOSRequest}
								/>
							) : (
								'Διαχειριστής Βάρδιας : ' + shiftManager
							)}
						</div>
						{children}
						{(leftMenuStatus || rightMenuStatus) && (
							<Portal>
								<div
									role="presentation"
									className={classNames('header-overlay', {
										'header-overlay-left-menu': leftMenuStatus,
										'header-overlay-right-menu': rightMenuStatus,
									})}
									onClick={() => {
										setAsideStatus(true)
										setLeftMenuStatus(false)
										setRightMenuStatus(false)
									}}
								/>
							</Portal>
						)}
					</div>
				</div>
			</header>
		</>
	)
}
Header.propTypes = {
	children: PropTypes.node.isRequired,
	hasLeftMobileMenu: PropTypes.bool,
	hasRightMobileMenu: PropTypes.bool,
}
Header.defaultProps = {
	hasLeftMobileMenu: true,
	hasRightMobileMenu: true,
}

export default Header
